<template>
  <LxpMobileHeader v-if="!isFull" :title="item.objNm">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-myclass" style="overscroll-behavior: none;">
    <!-- main-content -->
    <div class="main-content main-component">
      <!-- hrdcloud-view-container -->
      <div class="hrdcloud-view-container">
        <!-- player:hrdcloud-player -->
        <!-- [!DEV] is-inactive 비활성화 클래스 -->
        <div class="player hrdcloud-player">
          <div class="player-container">
            <!-- player-inner -->
            <div class="player-inner">
              <!-- player-viewport -->
                <!-- player-video -->
                <div v-if="item.lrnObjTyCd === lrnObjTyCds.MP3 || item.lrnObjTyCd === lrnObjTyCds.MP4" class="player-viewport">
                  <LearnVideo
                              :item="item"
                              :cms="cms"
                              :memory-limit="memoryLimit"
                              :use-cold-call="useColdCall"
                              :uuid="uuid"
                              @toggleFull="toggleFull"
                  />
                </div>
                <div v-else-if="item.lrnObjTyCd === lrnObjTyCds.PDF" class="player-viewport">
                  <div  class="player-pdf">
                    <LearnPdf
                        :cms="cms"
                        :uuid="uuid"
                    />
                  </div>
                </div>

                <div v-else-if="item.lrnObjTyCd === lrnObjTyCds.HTML" style="height:calc(100vh - 150px); overscroll-behavior: none;">
                  <LearnHtml
                             :cms="cms"
                             :uuid="uuid"
                  />
                </div>
                <!-- player-progressbar-wrapper -->

              <!-- //player-viewport -->
            </div>

            <!-- player-details -->
            <div class="player-details">
              <div class="detail-contents">
                <div class="content-top">
                  <div class="tags">
                    <span class="text">{{ item.lrnObjTyNm }}</span>
                    <span v-if="item.chapNm" class="text tag">{{ item.chapNm }}</span>
                  </div>
                </div>
                <h3 class="title">{{ item.objNm }}</h3>
                <div class="metadata-container">
                  <div class="metadata">
                    <span v-if="item.objBgngDt > 0 && item.objEndDt > 0" class="text text-silver">{{
                        timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd.w')
                      }} ~ {{ timestampToDateFormat(item.objEndDt, 'yyyy.MM.dd.w') }} | {{
                        timestampToDateFormat(item.objEndDt, 'yyyy.MM.dd hh:mm')
                      }}까지</span>
                  </div>
                </div>
              </div>
              <div v-if="item.lrnObjTyCd === lrnObjTyCds.PDF" class="detail-contents">
                <div class="metadata-container">
                  <a href="javascript:" v-if="pdfUrl && pdfUrl.length > 0" class="file-link kb-btn kb-btn-outline rounded-lg" @click.stop="downloadFile" style="width:100%">
                    <span class="filename">{{ `${item.objNm}.pdf` }}</span>
                    <i class="icon-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //player:hrdcloud-player -->
      </div>
      <!-- //hrdcloud-view-container -->
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {clearTargetTimeout, getItem, getUrl, lengthCheck, timestampToDateFormat, winOpen} from '@/assets/js/util';
import {enableLearn, lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import LearnHtml from '@/components/learn/window/LearnHtml';
import LearnPdf from '@/components/learn/window/LearnPdf';
import {guid} from '@/assets/js/ui.init';
import LearnVideo from '@/components/learn/window/LearnVideo';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {useRoute, useRouter} from 'vue-router';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {isInApp} from "@/assets/js/navigationUtils";
import {executeApps} from "@/assets/js/nativeAppUtils";
import ApiService from "@/assets/js/api.service";

export default {
  name: 'MobileLearnCms',
  components: {LxpMobileHeader, LearnVideo, LearnPdf, LearnHtml},
  props: {
    item: Object,
    cms: Object,
    memoryLimit: {
      type: Number,
      default: 0,
    },
    useColdCall: Boolean,
    lrnPsbltHrDiv: String,
  },
  emits: ['renewEnd'],
  setup(props, {emit}) {
    const router = useRouter();
    const route = useRoute();

    const isFull = ref(false);
    let isEmit = false;
    const toggleFull = change => { isFull.value = change; }

    const pdfUrl = computed(() => {
      return getUrl(props.cms.etnUrl);
    });

    const clickBack = () => {
      isEmit = true;
      emit('renewEnd');
      const currentQuery = {...route.query};
      delete currentQuery.modal;
      router.push({query: currentQuery});
    };

    const isVacation = ref(false);

    const checkVacation = () =>{
      ApiService.get('/v1/app/auth/vacation').then(res => {
        if(lengthCheck(res)) {
          if(getItem(res) > 0){
            isVacation.value = true;
          } else {
            isVacation.value = false;
          }
        }
      });
    }
    checkVacation();

    let checkInterval = props.lrnPsbltHrDiv === '02' || props.lrnPsbltHrDiv === '03' ?
        setInterval(() => {if (!isVacation.value && !enableLearn(props.lrnPsbltHrDiv, true)) { clickBack(); }}, 5 * 1000) :
        0
    ;

    const downloadFile = () => {
      winOpen(pdfUrl.value, '_blank');
    };




    onUnmounted(() => {
      if (!isEmit) emit('renewEnd');
      clearTargetTimeout(checkInterval);

      //isinapps
      console.log("apps check unmount", isInApp);
      if(isInApp){
        let jobJson = {
          cmd : "setDevicePullToRefresh",
          enable : "true"
        }
        executeApps("WEBVIEW", jobJson);

      }

    });

    onMounted(()=>{

      console.log("apps check mount", isInApp);
      if(isInApp){
        let jobJson = {
          cmd : "setDevicePullToRefresh",
          enable : "false"
        }
        executeApps("WEBVIEW", jobJson);

      }
    });

    return {
      uuid: guid(12),
      isFull,
      toggleFull,
      clickBack,
      timestampToDateFormat,
      lrnObjTyCds,
      downloadFile,
      pdfUrl
    };
  },
};
</script>
