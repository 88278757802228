<template>
  <LxpMobileHeader title="AFPK/CFP 윤리교육 현황">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="closeModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>

    <template v-slot:right>
<!--      <div class="util util-more">-->
<!--        <div class="dropdown" :class="{'is-active': toggles.download}" data-offset="header-right">-->
<!--          <button class="dropdown-btn" @click.stop="toggles.download = !toggles.download"><i class="icon-more"></i></button>-->
<!--          <div class="dropdown-target">-->
<!--            <div class="dropdown-box">-->
<!--              <ul class="dropdown-option-list">-->
<!--                <li class="dropdown-option-item">-->
<!--                  <a href="javascript:" class="dropdown-option-link" @click.stop="captureLearn"><span class="dropdown-option-text">이미지 다운로드<i class="icon-download"></i></span></a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;  &ndash;&gt;-->
<!--      </div>-->
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-talentboard-admin" @click="toggles.download = false">
    <!-- main-content -->
    <div ref="mainRef" class="main-content">
      <div class="profile-list-container">
        <section class="content-section">
          <header class="section-header">
            <h3 class="title font-bodyB">AFPK/CFP 윤리교육</h3>
          </header>

          <div class="content-scrollwrap d-block mb-2">
            <div class="kb-table kb-table-bordered kb-table-padding kb-table-equal-padding hide-scroll-bar mb-3" style="min-width: 1070px;">
              <table>
                <tbody>
                <tr>
                  <th class="cell-border kb-th"><span class="th-title">성명(직원번호)</span></th>
                  <td class="padding-12 cell-border"><span class="td-text">{{session.lrnerNm}}({{session.lrnerId}})</span></td>

                  <th class="cell-border kb-th"><span class="th-title">소속</span></th>
                  <td class="padding-12 cell-border"><span class="td-text">{{session.deptNm}}</span></td>

                  <th class="cell-border kb-th"><span class="th-title">직위</span></th>
                  <td class="padding-12 cell-border"><span class="td-text">{{session.jbgdCd}}</span></td>

                  <th class="cell-border kb-th"><span class="th-title">생년월일</span></th>
                  <td class="padding-12 cell-border"><span class="td-text">{{getStrWithDash(info.brdt, '-')}}</span></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>

          <div class="content-scrollwrap d-block">
            <div class="kb-table kb-table-bordered kb-table-padding kb-table-equal-padding mb-3 hide-scroll-bar" style="min-width: 1070px;">
              <table>
                <colgroup>
                  <col>
                  <col>
                  <col>
                  <col>
                  <col>
                </colgroup>
                <thead>
                <tr>
                  <th class="cell-border"><span class="th-title">과정차수명</span></th>
                  <th class="cell-border"><span class="th-title">학습기간</span></th>
                  <th class="cell-border"><span class="th-title">콘텐츠명</span></th>
                  <th class="cell-border"><span class="th-title">누적 진도율</span></th>
                  <th class="cell-border"><span class="th-title">학습완료여부</span></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, index) in renderItems" :key="index">
                  <tr v-if="item.learns && item.learns.length > 0">
                    <td :rowspan="item.learns.length" class="padding-12 cell-border"><span class="td-text">{{item.crseNm}}</span></td>
                    <td :rowspan="item.learns.length" class="padding-12 cell-border"><span class="td-text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                    <td class="padding-12 cell-border"><span class="td-text">{{item.learns[0].objNm}}</span></td>
                    <td class="padding-12 cell-border"><span class="td-text" v-html="getScore(item.learns[0], '2066019')"></span></td>
                    <td class="padding-12 cell-border">
                      <span class="td-text" v-html="getFinishText(item, item.learns[0], '2066019')"></span>
                    </td>
                  </tr>
                  <template v-for="(learn, idx) in item.learns" :key="`${index}-${idx}`">
                    <tr v-if="idx > 0">
                      <td class="padding-12 cell-border"><span class="td-text">{{learn.objNm}}</span></td>
                      <td class="padding-12 cell-border"><span class="td-text" v-html="getScore(learn, '2066019')"></span></td>
                      <td class="padding-12 cell-border">
                        <span class="td-text" v-html="getFinishText(item, learn, '2066019')"></span>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div>
          </div>

        </section>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import {learnRegularEthicsStatusSetup} from '@/assets/js/modules/learn/learn-regular-status-setup';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';

export default {
  name: 'MobileLearnRegularEthicsStatus',
  components: {LxpMobileHeader},
  props: {
    toggle: Boolean,
    params: Object,
    items: Array
  },
  emits: ['update:toggle'],
  setup: learnRegularEthicsStatusSetup
};
</script>

