<template>
  <template v-if="windowActive && modalActive && targetIndex > -1">
    <MobileLearnCms
        v-if="modalActive === 'cms'"
        :cms="targetAdded"
        :item="targetItem"
        :memory-limit="7"
        @renewEnd="getLearnObjectResult"
        :use-cold-call="params.coldCallUseYn === 'Y'"
        :lrn-psblt-hr-div="params.lrnPsbltHrDiv"
    />
  </template>
  <template v-else-if="statusToggle">
    <MobileLearnRegularEthicsStatus
        v-if="ethicsStatuses.includes(params.splCrseTyCdDcd)"
        v-model:toggle="statusToggle"
        :items="items"
        :params="params"
    />
    <MobileLearnRegularStatus
        v-else
        v-model:toggle="statusToggle"
        :items="items"
    />
  </template>

  <template v-else>
    <LxpMobileHeader title="학습홈">
      <template v-slot:left>
        <div class="util util-back">
          <router-link :to="{name: routeTarget}" class="util-actions util-actions-back" >
            <i class="icon-history-back"></i>
          </router-link>
        </div>
      </template>
    </LxpMobileHeader>
    <main ref="mainRef" class="kb-main" id="kb-myclass">
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="educourse-view-container">
          <MobileLearnInfo
              v-if="readies.course" :params="params" :learns="objectResults"
          />

          <div v-if="params.splCrseTyCdDcd && !starLinkStatuses.includes(params.splCrseTyCdDcd)" class="main-header" style="margin-bottom: 32px">
            <div class="header-snb">
              <nav class="snb" style="justify-content: right; margin-top: 0;">
                <ul class="snb-list">
                  <li class="snb-item">
                    <button class="is-active" @click="showStatus">{{ ethicsStatuses.includes(params.splCrseTyCdDcd) ? 'AFPK/CFP 윤리교육 현황' : '판매자격연수 현황' }}</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="view-contents">
            <!-- content-section:오늘 예정된 학습 -->
            <MobileLearnRegularIndex
                :params="params"
                :items="objectResults"
                :current-date="currentDate"
                @openLearn="openLearn"
            />
          </div>
        </div>
      </div>
      <!-- //main-content -->
    </main>
  </template>
</template>

<script>

import MobileLearnCms from '@/components/learn/mobile/window/MobileLearnCms';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileLearnInfo from '@/components/learn/mobile/MobileLearnInfo';
import MobileLearnRegularIndex from '@/components/learn/mobile/MobileLearnRegularIndex';
import MobileLearnRegularStatus from '@/components/learn/regular/mobile/MobileLearnRegularStatus';
import {learnRegularMainSetup} from '@/assets/js/modules/learn/learn-regular-setup';
import MobileLearnRegularEthicsStatus from '@/components/learn/regular/mobile/MobileLearnRegularEthicsStatus';

export default {
  name: 'MobileLearnRegularMain',
  components: {
    MobileLearnRegularEthicsStatus,
    MobileLearnRegularStatus,
    MobileLearnRegularIndex,
    MobileLearnInfo,
    LxpMobileHeader,
    MobileLearnCms,
  },
  setup: learnRegularMainSetup
};
</script>
