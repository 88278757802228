<template>
  <article class="content-section pt-0">
<!--    <header class="section-header d-flex">-->
      <!--      <div v-if="tab === 'today'" class="header-side"><span class="text-sm text-muted">2022년 3월 30일 수요일</span></div>-->
<!--      <div v-if="tab === 'today'" class="header-side"><span class="text-sm text-muted">{{ currentDateStr }}</span></div>-->
<!--    </header>-->

    <div class="symbol-item-wrap symbol-item-margin" data-content="row">
      <!-- symbol-item -->
        <div  v-for="(item, index) in renderItems" :key="index" class="symbol-item">
          <div class="item-row row-content">
            <div class="item-icon">
              <i :class="getSymbolType(item.lrnObjTyCd)"></i>
            </div>

            <div class="item-learning">
              <div class="content-subtitle">{{ item.lrnObjTyNm === 'MP4' ? '동영상' : item.lrnObjTyNm }}</div>

              <div v-if="item.splCrseTyCdDcd === '2066015'" class="content-title">{{ item.chapNm ? item.chapNm : '-' }}</div>
              <div v-else class="content-title">{{ item.objNm  }}</div>

              <div class="content-texts">
                <span v-if="item.addedMetas.length > 0" class="text">{{ item.addedMetas.join(' ・ ')}}</span>
                <span v-if="item.rangeDate" class="text">{{ item.rangeDate }}</span>
              </div>
              <!-- 학습 점수 처리 -->
              <div class="content-texts">
                <strong class="text-description text-gold" v-html="getScoreUnit(item, params.coldCallUseYn)"></strong>
              </div>
            </div>
          </div>
          <div class="item-row row-buttons">
            <button v-if="canDo(item, currentDate)" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')">
              <span class="text">학습하기</span>
            </button>
            <button v-else class="kb-btn kb-btn-outline" disabled>
              <span class="text">학습하기</span>
            </button>
          </div>
        </div>
    </div>
  </article>
</template>

<script>
import {learnRegularIndexSetup} from '@/assets/js/modules/learn/learn-regular-setup';

export default {
  name: 'MobileLearnRegularIndex',
  components: {},
  props: {
    params: Object,
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnRegularIndexSetup
};
</script>
